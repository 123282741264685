.top-button-container {
    position: fixed;
    box-sizing: border-box;
    display: flex;
    flex-direction: "row";
    justify-content: space-between;
    bottom: 2.5%;
    padding-right: 16px;
    padding-left: 16px;
    width: 100%;
    text-align: right;
    font-size: calc(10px + 2vmin);
    color: oklch(50% 0 265);
}

.top-button {
    width: calc(20px + 5vmin);
    height: calc(20px + 5vmin);
    background-color: oklch(50% 0 265);
    text-align: center;
    border-radius: 90px;
    opacity: 0;
    box-shadow: 5px 5px 10px black;
    transition: opacity 1s ease-out;
}

.insurance-logo {
    width: calc(20px + 5vmin);
    height: calc(20px + 5vmin);
    background-color: oklch(50% 0 265);
    text-align: center;
    opacity: 0;
    box-shadow: 5px 5px 10px black;
    transition: opacity 1s ease-out;
}

.insurance-logo img {
    width: calc(20px + 5vmin);
    height: calc(20px + 5vmin);
}

.fade-in {
    opacity: 0.7;
    transition: opacity 1s ease-out;
}

.fade-in:hover {
    background-color: oklch(10% 0.15 250);
    cursor: pointer;
}
