#imprint {
    background-color: oklch(40% 0 265);
    color: oklch(100% 0 360);
    font-size: calc(15px + 3vmin);
    padding: calc(10px + 5vmin);
    overflow: auto;
}

.imprint-additional-text {
    font-size: calc(9px + 2vmin);
    color: oklch(80% 0 360);
}

.imprint-small-text {
    font-size: calc(9px + 1vmin);
    color: oklch(80% 0 360);
}

a {
    text-decoration: none;
    color: oklch(80% 0 360);
}
