#services {
    background-color: oklch(20% 0.15 250);
    color: oklch(100% 0 360);
    font-size: calc(15px + 3vmin);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    overflow: auto;
}

.services-inner-container {
    flex: 1;
    display: flex;
    align-items: center;
    padding: calc(24px + 5vmin);
    text-align: justify;
    box-sizing: border-box;
    min-height: 50%;
}

.services-additional-text {
    font-size: calc(9px + 2vmin);
    color: oklch(80% 0 360);
}
.list-headline {
    font-size: calc(12px + 2vmin);
}

ul {
    font-size: calc(9px + 2vmin);
    color: oklch(80% 0 360);
    list-style: square;
    text-align: left;
}

li {
    padding: calc(2px + 1vmin);
}

.services-small-text {
    font-size: calc(9px + 1vmin);
    color: oklch(80% 0 360);
}

a {
    text-decoration: none;
    color: oklch(80% 0 360);
}

@media screen and (max-width: 1280px) {
    .services-additional-text {
        font-size: calc(10px + 1vmin);
    }
    ul {
        font-size: calc(10px + 1vmin);
    }
    #services {
        font-size: calc(11px + 2vmin);
    }
}

@media screen and (max-width: 1000px) {
    #services {
        display: block;
    }
    .no-padding-top {
        padding: calc(12px + 2vmin);
        padding-top: 0px;
        align-items: flex-start;
    }
    .no-padding-bottom {
        padding: calc(12px + 2vmin);
        padding-bottom: 0px;
    }
}
