.App {
    text-align: center;
}

.App-logo {
    width: 80%;
    pointer-events: none;
    animation: company-logo-pop 2s ease-out;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    overflow: hidden;
    box-sizing: border-box;
}

.App-header {
    background-image: url("../assets/bg_bingen.jpg");
    background-size: cover;
    background-position: center;
}

.category-link-container {
    width: 80%;
    padding-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.category-link {
    font-size: calc(10px + 5vmin);
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
    color: oklch(40% 0 265);
    opacity: 0;
    animation: category-fade-in 1.5s ease-in forwards;
}

a:hover {
    color: oklch(10% 0.15 250);
}

a:active {
    color: oklch(30% 0 265);
}

@media screen and (max-width: 1280px) {
    .category-link {
        font-size: calc(10px + 3vmin);
    }
}

@media screen and (max-width: 900px) {
    .category-link-container {
        flex-direction: column;
    }
    .category-link {
        padding-top: 10px;
    }
}

@keyframes company-logo-pop {
    from {
        width: 0%;
        height: 0%;
    }
    to {
        width: 80%;
        height: auto;
    }
}

@keyframes category-fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
