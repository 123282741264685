#products {
    background-color: oklch(30% 0.15 250);
    color: oklch(100% 0 360);
    font-size: calc(15px + 3vmin);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.products-inner-container {
    height: 100vh;
    width: 100%;
    background-color: #000000;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: justify;
    box-sizing: border-box;
    overflow: hidden;
    background-image: url("../assets/products/spaceopera/horizontal.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.products-headline {
    text-align: center;
    width: 100%;
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
}

.products-headline-text {
    width: 100%;
    background-color: hsla(0, 0%, 20%, 0.8);
    padding: 8px;
    font-size: 2.5em;
    box-sizing: border-box;
    display: block;
}

.products-screenshots {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    height: 70vh;
    align-items: center;
}

.product-image {
    flex: 1;
    width: auto;
    height: calc(30vh + 20vmin);
    max-height: 90%;
    object-fit: contain;
    padding: 0px;
    margin: 10px;
    border-radius: 24px;
    box-shadow: 10px 5px 5px black;
}

.products-storelink {
    text-align: center;
    flex: 1;
}

.google-link-button {
    height: 100px;
    width: 250px;
    border-radius: 13px;
}

.apple-link-button {
    display: inline-block;
    overflow: hidden;
    border-radius: 13px;
    width: 250px;
    height: 83px;
}

.apple-link-image {
    border-radius: 13px;
    width: 250px;
    height: 83px;
}

.products-description {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
}
.products-additional-text {
    text-align: center;
    width: 85%;
    background-color: hsla(0, 0%, 20%, 0.9);
    padding: 16px;
    border-radius: 16px;
    color: oklch(100% 0 180);
    font-size: calc(0px + 3vmin);
    box-sizing: border-box;
}

.products-small-text {
    font-size: calc(9px + 1vmin);
    color: oklch(80% 0 360);
}

a {
    text-decoration: none;
    color: oklch(80% 0 360);
}

@media screen and (max-width: 1280px) {
    .services-additional-text {
        font-size: calc(10px + 1vmin);
    }
    .products-additional-text {
        font-size: calc(10px + 3vmin);
    }
    ul {
        font-size: calc(10px + 1vmin);
    }
    #services {
        font-size: calc(11px + 2vmin);
    }

    .product-image {
        flex: 1;
        width: auto;
        height: calc(30vh + 10vmin);
        object-fit: contain;
        padding: 0px;
        margin: 10px;
        border-radius: 24px;
        box-shadow: 10px 5px 5px black;
    }
}

@media screen and (max-width: 800px) {
    .product-image {
        flex: 1;
        width: auto;
        height: calc(25vh + 5vmin);
        object-fit: contain;
        padding: 0px;
        margin: 10px;
        border-radius: 24px;
        box-shadow: none;
        flex-basis: 33%;
    }
}
