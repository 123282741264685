.language-link:hover {
    color: oklch(10% 0.15 250);
}

.lang-switch {
    position: fixed;
    box-sizing: border-box;
    top: 0;
    padding-right: 16px;
    width: 100%;
    text-align: right;
    font-size: calc(10px + 2vmin);
    color: oklch(50% 0 265);
}

.language-link {
    text-decoration: none;
    color: oklch(50% 0 265);
}

.language-link-active {
    text-decoration: none;
    color: oklch(10% 0.15 250);
}
